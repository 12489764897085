import React, { useEffect, useState } from "react";
import styleClasses from "../order.module.scss";
import * as CONSTANT from "../../../constants";
import ItemPrice from "../../../components/Cart/CartItems/ItemPrice";
import { cartItem } from "../../../models/cart.model";
import {
  isCartModifiersUnavailable,
  isItemModifiersUnavailable,
  notAvailableAddedModifiers,
} from "../../../helpers/helperMethods";
import FavoriteButton from "../../try2/Customization/FavoriteButton";

interface IOrderDetailsProps {
  Titlehide?: any;
  Imagehide?: any;
  Items?: any;
  isOrdered?: boolean;
  ScrollCard?: any;
  Buttonspace?: any;
  cartDetail?: any;
  orderDetails?: any;
  refetchOrderDetails?: () => any;
  labelFavoriteItemName: string;
  decideShowLabelOrDelectionModal: () => any;
  isFavoriteItem: boolean;
  favoriteButtonStyles?: string;
  title?: string;
}
const OrderDetails = (props: IOrderDetailsProps) => {
  const {
    Titlehide,
    Imagehide,
    Items,
    isOrdered = false,
    orderDetails,
    refetchOrderDetails,
    labelFavoriteItemName,
    decideShowLabelOrDelectionModal,
    isFavoriteItem,
    favoriteButtonStyles,
    title,
  } = props;

  const { ScrollCard, Buttonspace } = props;
  const [isShowLess, setIsShowLess] = useState<boolean>(true);
  const [loadCount, setLoadCount] = useState(5);
  const [showCYOModifers, setShowCYOModifers] = useState({});
  const displayPrice = (item: cartItem, itemQuantity: number) => {
    return parseFloat((item.gross_price * itemQuantity).toFixed(2));
  };
  const discountedPrice = (item: cartItem, itemQuantity: number) => {
    return parseFloat(
      (
        item.gross_price * itemQuantity -
        (item.discount_amount || item.discount || item.base_price)
      ).toFixed(2)
    );
  };
  const isItemUnavailable = (item: any) => {
    let itemsNotAvailable = false;
    if (item?.is_try_2_combo || item?.is_kids_combo) {
      item?.modifiers?.findIndex((subItem, index) => {
        if (!subItem?.available && subItem?.is_item) {
          itemsNotAvailable = true;
          return;
        }
      });
    } else {
      if (!item?.available) {
        itemsNotAvailable = true;
      }
    }
    return itemsNotAvailable;
  };
  useEffect(() => {
    if (isShowLess) {
      setLoadCount(5);
    } else {
      setLoadCount(Items.length);
    }
  }, [isShowLess]);

  const handleCYOModifiersShowMore = (itemId) => {
    setShowCYOModifers((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
  };
  const itemNames = (items: any) => {
    if (items?.is_try_2_combo || items?.is_kids_combo) {
      const names = items?.modifiers.map((item: any, index: number) => {
        if (item?.is_item) {
          return (
            <ul className="ps-3 mb-1">
              <li className="f-s14 text-start font-Visby-cf clr-text-grey list-style-none text-captilize">
                {item?.modifier_name}
              </li>
            </ul>
          );
        }
        return null;
      });
      return names;
    }
    return <></>;
  };

  return (
    <>
      <div className="d-flex justify-content-start gap-3 align-items-center">
        <h2
          className={`f-s20 font-Cls text-uppercase f-sm-s16 text-start h-26 mb-0 ${Titlehide}`}
        >
          {title}
        </h2>
        <div className={Titlehide}>
          <FavoriteButton
            favIcon={true}
            buttonHandler={decideShowLabelOrDelectionModal}
            className="d-block d-lg-block position-static ms-auto mb-2 favorite-icon-blue"
            // item={order}
            favourite={isFavoriteItem}
            fromOrder={true}
            isIconblueFill={true}
          />
        </div>
      </div>
      {/* <h2 className="text-start">{labelFavoriteItemName}</h2> */}

      <div className={`mt-md-3 ${styleClasses.order_parent} ${ScrollCard}`} data-testid="checkout-list-items">
        {Items?.slice(0, loadCount).map((item) => (
          <React.Fragment key={item.id}>
            <div
              className={`my-3 ${styleClasses.order_details_card_wrapper} ${
                !item.available && !isOrdered ? styleClasses.detail_error : " "
              }`}
              data-testid="checkout-item"
            >
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className={`d-flex align-items-center ${styleClasses.order_details_img}`}
                >
                  <div className="d-none d-md-block">
                    <img
                      className={`${Imagehide}`}
                      alt={item.name}
                      src={item?.show_modifier_name ? `${CONSTANT.REACT_APP_CLOUD_FRONT}/Modifiers/${item.modifiers?.[0].modifier_id}/modifier-${item.modifiers?.[0].modifier_id}.webp` : `${CONSTANT.REACT_APP_CLOUD_FRONT}/Items/${item.item_id}/item-${item.item_id}.webp`}
                    />
                  </div>

                  <div className="ps-md-2">
                    <h5 className="f-s18 f-sm-s14 text-start font-Cls text-capitalize mb-0" data-testid='checkout-itemName'>
                      {item?.show_modifier_name ? item.modifiers?.[0].modifier_name :  item.name}
                    </h5>
                    <div className="f-s14 text-start font-Visby-cf clr-text-grey ">
                      {itemNames(item)}
                      <span data-testid="checkout-itemQuantity">{item.quantity}</span> x{" "}
                      {item?.single_size
                        ? null
                        : `${item?.item_size ?? item?.size} Order`}
                    </div>
                    <span className="d-block f-s14 font-Visby-cf f-w6 mt-1 text-capitalize text-start">
                      {item?.recipient_name}
                    </span>
                  </div>
                </div>
                <div className="text-center d-none d-md-block ">
                  {/*<p className="f-s14 clr-text-grey mb-0 font-Visby-cf">Total</p>*/}
                  {/*<p className="font-Visby-cf f-s16 mb-0 f-w6">${item.display_price}</p>*/}
                  <ItemPrice
                    price={displayPrice(item, item.quantity)}
                    isRedeemed={
                      !!item.reward_redeemed || !!item.redemption_by_points
                    }
                    discountPrice={discountedPrice(item, item.quantity)}
                  />
                </div>
              </div>
              <div>
                {item.category_id === Number(CONSTANT.BYO_CATEGORY_ID) ? (
                  <div className="cz-ms-100 ps-md-2 ps-0 cz-ms-sm-0">
                    {" "}
                    {item.modifiers
                      .slice(
                        0,
                        showCYOModifers[item.id] ? item.modifiers.length : 1
                      )
                      .map((modifier) => {
                        return (
                          <p
                            className="clr-dark-blue mb-0 f-s14 text-start"
                            key={modifier.id}
                          >
                            {" "}
                            + {modifier?.modifier_name}{" "}
                            {modifier.quantity > 1 && `(${modifier?.quantity})`}
                          </p>
                        );
                      })}{" "}
                    {item.modifiers.length > 1 && (
                      <div
                        className="cursor-pointer clr-sb-dark text-start"
                        onClick={() => {
                          handleCYOModifiersShowMore(item.id);
                        }}
                      >
                        {!showCYOModifers[item?.id] ? "see more" : "see less"}
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
            {isItemUnavailable(item) && !isOrdered && (
              <div
                className={`w-100 text-center mx-auto px-2 px-md-0 font-rale f-s14 clr-dark-red ${styleClasses.error_msg}`}
              >
                This item is not available.
                <br />
                Please remove from your order.
              </div>
            )}
            {isItemModifiersUnavailable(item) && !isOrdered && (
              <div
                className={`w-md-50 w-100 text-center px-2 px-md-0 mx-auto font-rale f-s14 clr-dark-red ${styleClasses.error_msg}`}
              >
                Unfortunately, we are currently out of{" "}
                {notAvailableAddedModifiers(item)?.join(", ")} Today.
                <br />
                Please remove from your order.
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
      {Items?.length > 5 && (
        <div
          className={`${styleClasses.less_more_btn} ${Buttonspace} bg-clr-white-clr py-2 mb-3`}
        >
          <button
            className="btn btn-custom d-flex align-items-center gap-2 justify-content-center py-2 font-Visby-cf f-s14 w-100"
            onClick={() => {
              setIsShowLess(!isShowLess);
            }}
            data-testid='checkout-show-moreItem'
          >
            {isShowLess ? (
              <img
                src={require("../../../../src/images/Plus.svg")}
                alt="plus icon"
              />
            ) : (
              <img
                src={require("../../../../src/images/Place Order/minus_icon.svg")}
                alt="minus icon"
              />
            )}
            {isShowLess ? "View More" : "View Less"}
          </button>
        </div>
      )}
      <div className={`${Titlehide} shadow-divider d-md-none mt-4`} />
    </>
  );
};

export default OrderDetails;

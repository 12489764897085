import * as Yup from "yup";

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must Enter Valid Email")
    .required("Must Enter Email Address"),
  password: Yup.string()
  .matches(
    /^(?!.*['"]).\S*$/,
    "Password must not contain spaces or quotation marks."
  )
  .required("Must Enter Password"),
});
